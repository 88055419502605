
  import WidgetWrapper from 'yoshi-flow-editor-runtime/build/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/agent00/work/1cfac24d837429e2/client/wixstores-client/wixstores-client-thank-you-page-ooi/src/components/thankYouPage/Widget/index.tsx';
  import {
    
  } from 'yoshi-flow-editor-runtime';

  import stylesParams from '/home/builduser/agent00/work/1cfac24d837429e2/client/wixstores-client/wixstores-client-thank-you-page-ooi/src/components/thankYouPage/stylesParams.ts';

  var sentryConfig = {
      DSN: 'https://5ce86095794d4b0dbded2351db71baf8@o37417.ingest.sentry.io/5792095',
      id: '5ce86095794d4b0dbded2351db71baf8',
      projectName: 'wixstores-client-thank-you-page-ooi',
      teamName: 'wixstores',
    };

  var UserComponent = WidgetWrapper({
      
    }, Widget, {
    sentryConfig,
    stylesParams,
    name: 'thankYouPage'
  });

  
    import { hot } from 'component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  const loadChunks = Widget.loadChunks;

  export default {
    component: UserComponent,
    loadChunks
  };
