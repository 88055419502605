// eslint-disable-next-line wix-custom-rules/wixstores-file-names
import {FormattedItem, Item, Option} from '../../../../../types/app.types';
import {getPlanFrequencyByDurationTranslationKey} from '../../../order.helper';
import {SubscriptionFrequency} from '@wix/wixstores-client-storefront-sdk/dist/es/src/graphql/queries-schema';
import {TranslationFunction} from 'react-i18next';

export const buildItemOptions = (
  item: Item,
  condition: (option: Option) => boolean
): {title: string; value: string}[] => {
  return item.options.filter(condition).map((option: Option) => ({
    title: option.title,
    value: option.description,
  }));
};

export const reformatItems = (items: Item[]): FormattedItem[] => {
  return items.map((item: Item) => ({
    product: {
      name: item.name,
      media: [item.media?.url ? item.media : null],
      productType: item.productType,
    },
    convertedPrices: {
      formattedPrice: item.formattedPrice,
      formattedTotalPrice: item.formattedTotal,
    },
    optionsSelectionsValues: item.options ? buildItemOptions(item, (option: Option) => !option.isFreeText) : [],
    freeText: item.options ? buildItemOptions(item, (option: Option) => option.isFreeText) : [],
    cartItemId: item.productId,
    quantity: item.quantity,
    digitalFileLink: item.digitalFileLink,
  }));
};

export const hasOptions = (item: FormattedItem) => {
  return item.optionsSelectionsValues.length > 0;
};

export const hasFreeText = (item: FormattedItem) => {
  return Boolean(item.freeText.filter((freeText) => freeText.value.trim()).length > 0);
};

export const getSubscriptionDuration = ({
  t,
  subscriptionDuration,
  subscriptionFrequency,
}: {
  t: TranslationFunction;
  subscriptionDuration: number;
  subscriptionFrequency: SubscriptionFrequency;
}) => {
  if (!subscriptionDuration) {
    return t('THANK_YOU_PAGE_PLAN_DURATION_DESCRIPTION_AUTORENEW');
  }

  const frequencyUnit = t(getPlanFrequencyByDurationTranslationKey(subscriptionFrequency, subscriptionDuration));
  if (subscriptionDuration === 1) {
    return t('THANK_YOU_PAGE_PLAN_DURATION_DESCRIPTION_SINGULAR', {
      frequencyUnitSingular: frequencyUnit,
    });
  }

  return t('THANK_YOU_PAGE_PLAN_DURATION_DESCRIPTION_PLURAL', {
    numberOfFrequencyUnits: subscriptionDuration,
    frequencyUnitSingular: frequencyUnit,
  });
};
