import React from 'react';
import {useControllerProps} from '../../ControllerContext';
import {FormattedItem} from '../../../../../types/app.types';
import {CommonProductLineItem} from './ProductLineItem';
import {hasFreeText, hasOptions, reformatItems} from './utils';

export const ProductLineItems = () => {
  const {items} = useControllerProps().thankYouPageStore;
  const formattedItems = reformatItems(items);

  return (
    <div>
      {formattedItems.map((item: FormattedItem, index: number) => {
        const allOptions = [];
        hasOptions(item) && allOptions.push(...item.optionsSelectionsValues);
        hasFreeText(item) && allOptions.push(...item.freeText);
        return (
          <CommonProductLineItem
            item={item}
            options={allOptions}
            key={item.cartItemId}
            withDivider={index !== formattedItems.length - 1}
          />
        );
      })}
    </div>
  );
};
