import React from 'react';
import {BillingColumn} from './BillingColumn';
import {ShippingColumn} from './ShippingColumn';
import * as s from './ShippingSection.scss';
import {PickupColumn} from './PickupColumn';

export enum ShippingSectionDataHook {
  root = 'ShippingSectionDataHook.root',
  BillingSection = 'ShippingSectionDataHook.billingSection',
}

export function ShippingSection({
  shouldShowShipping,
  shouldShowBilling,
  isPickupSelected,
}: {
  shouldShowShipping: boolean;
  shouldShowBilling: boolean;
  isPickupSelected: boolean;
}) {
  return (
    <div className={s.section} data-hook={ShippingSectionDataHook.root}>
      {shouldShowShipping && <Column>{isPickupSelected ? <PickupColumn /> : <ShippingColumn />}</Column>}
      {shouldShowBilling && (
        <Column dataHook={ShippingSectionDataHook.BillingSection}>
          <BillingColumn />
        </Column>
      )}
    </div>
  );
}

const Column: React.FC<{dataHook?: string}> = (props) => {
  return (
    <div className={s.column} data-hook={props.dataHook}>
      {props.children}
    </div>
  );
};
